import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Hero, About, Teaching, Education, Trainings, Wkst, Cocurricular, Research, Courses, Jobs, Certificate, Achievement, Award, Projects, Contact } from '@components';

const StyledMainContainer = styled.main`
  counter-reset: section;
`;

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <StyledMainContainer className="fillHeight">
      <Hero />
      <About />
      <Jobs />
      <Teaching />
      <Research />
      <Wkst />
      <Cocurricular />
      <Education />
      <Trainings />
      <Courses />
      <Projects />
      <Certificate />
      <Achievement />
      <Award />
      <Contact />
    </StyledMainContainer>
  </Layout>
);

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
};

export default IndexPage;
